import config from '../config/config'
import getImageUrl from '../helpers/image-url'
import { ILocaleValue } from './locale-value.entity'
interface IBrandMedia {
	attributes: {
		image: string
	}
}
export class BrandEntity {
	id: number
	name: string
	slug: string
	media: IBrandMedia[]
	top_layout: any
	subcategory_layout: any
	content_layout: any[]
	seo_content: any[]
	created_at: string

	get image() {
		const imageName = this.media && this.media[0] && this.media[0].attributes?.image
		return getImageUrl(imageName)
	}
}
export class BrandResponse {
  data: BrandEntity[]
}
