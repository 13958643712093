import { render, staticRenderFns } from "./OfferCardImage.vue?vue&type=template&id=3c19f49f&"
import script from "./OfferCardImage.vue?vue&type=script&lang=ts&"
export * from "./OfferCardImage.vue?vue&type=script&lang=ts&"
import style0 from "./OfferCardImage.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OfferCardImageBg: require('/var/www/html/components/OfferCardImage/OfferCardImageBg.vue').default})
